import store from '@/store'
import axios from 'axios'
import jwt from 'jsonwebtoken' 
import moment from 'moment'
import System from '../system'

export default{
    get token(){
        return store.state.auth.user?.Token || null
    },
    get isAuthenticated(){
        if(this.tokenExpireTime && moment().isBefore(moment(this.tokenExpireTime))){
            if(this.refreshTokenExpireTime && moment().isBefore(this.refreshTokenExpireTime)){
                return store.state.auth.isAuthenticated
            }else return false
        }else return false
    },
    set isAuthenticated(bool){
        store.commit('auth/SetUserAuthenticate', bool)
    },
    get refreshToken(){
        return store.state.auth.user?.RefreshToken || null
    },
    get tokenExpireTime(){
        let token = store.state.auth.user?.Token
        if(token){
            const time = jwt.decode(token)?.exp
            return Number(String(time).padEnd(13,"0")) || null
        }return null
    },
    get refreshTokenExpireTime(){
        return store.state.auth.user?.RefreshTokenExpireTime || null
    },
    get user(){
        return store.state.auth.user
    },
    set user(user){
        System.GStatus = user?.GStatus == "1" ? true : false
        store.commit('auth/SetUser', user)
        var token = user?.Token || null
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    },
    get roleGroup(){
        let token = store.state.auth.user?.Token
        if(token){
            return jwt.decode(token)?.RoleGroup || null
        }return null
    },
    get companyId(){
        let token = store.state.auth.user?.Token
        if(token){
            return jwt.decode(token)?.CompanyId || null
        }return null
    },
    get companyName(){
        let token = store.state.auth.user?.Token
        if(token){
            return jwt.decode(token)?.CompanyName || null
        }return null
    },
    get roles(){
        return store.state.auth.user?.Roles || []
    },
    set roles(roles){
        store.commit('auth/SetRoles', roles)
    },
    get isAdmin(){
        return store.state.auth.user?.IsAdmin || false
    },
    set isAdmin(bool){
        store.commit('auth/SetIsAdmin', bool)
    },
    hasRole(array){
        if(this.isAdmin === false){
            if(this.roles){
                return this.roles.some(role => array.includes(role))
            }else return false
        }else return true
    },
    refreshData(t,rt,rtet){
        store.commit('auth/RefreshData',{t:t,rt:rt,rtet:rtet})
    },
    get isResponsible(){
        return store.state.auth.user?.IsResponsibleUser || false
    }
}