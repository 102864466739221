<template>
    <div class="v-validator" :class="{'is-invalid':hasError}">
        <slot></slot>
        <b-badge v-if="hasError" variant="danger" class="mt-50 ml-50">
            <feather-icon icon="AlertCircleIcon"></feather-icon>
            {{errorText}}
        </b-badge>
    </div>
</template>

<script>
import {BBadge} from 'bootstrap-vue'
import objectPath from 'object-path'
export default {
    components:{
        BBadge
    },
    props:{
        errors:{
            type:Object,
            required:true
        },
        parameter:{
            type:String,
            required:true
        },
        watch:{
            required:true
        }
    },
    watch:{
        watch(){
            this.$delete(this.errors, this.parameter)
        }
    },
    computed:{
        hasError(){
            return objectPath.has(this.errors,this.parameter)
        },
        errorText(){
            if(this.hasError){
                return objectPath.get(this.errors,this.parameter)
            }else return ""
        }
    }
}
</script>

<style>
.v-validator.is-invalid{
    border-radius: 0.375rem;
}

/** FOR ANY WRAPPER */
.v-validator.is-invalid .validator-wrapper{
    border:1px solid #ff2442;
}

/** FOR FLATPICKER */
.v-validator.is-invalid .form-control:not(.flatpickr-input){
    border-color: #ff2442;
}

/** FOR V-SELECT */
.v-validator.is-invalid .vs__dropdown-toggle{
    border-color: #ff2442;
}
</style>