export default {
    namespaced: true,
    state:{
        isAuthenticated: false,
        user: {},
    },
    mutations:{
        SetUserAuthenticate(state, bool){
            state.isAuthenticated = bool
        },
        SetUser(state, user){
            state.user = user
        },
        SetIsAdmin(state,bool){
            state.user.IsAdmin = bool
        },
        SetRoles(state, roles){
            state.user.Roles = roles
        },
        RefreshData(state,data){
            state.user.Token = data.t
            state.user.RefreshToken = data.rt
            state.user.RefreshTokenExpireTime = data.rtet
        }
    },
    getters:{},
    actions:{}
}