export default{
    namespaced: true,
    state:{
        isModalOpen:false,
    },
    mutations:{
        setIsModalOpen(state,value){
            state.isModalOpen = value;
        }
    },
    getters:{},
    setters:{}
}