import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function result(res,vue,ex){
  let response = res?.data
  let _variant = ""
  let _icon = ""
  let _message = ""

  if(ex){
    if(ex.response?.status == 401){
      _variant = "warning"
      _icon = "ShieldIcon"
      _message = "Bu işlem için yetkiniz yok."
    }else if(ex.response?.status == 404){
      _variant = "danger"
      _icon = "AlertCircleIcon"
      _message = "Gerekli istemci bulunamadı."
    }else if(ex.response?.status == 400){
      if (vue) {
        if (vue.errors) {
          vue.errors = ex?.response?.data?.Data
        }
      }
    }else{
      _variant = "danger"
      _icon = "AlertCircleIcon"
      _message = "Bilinmeyen bir hata oluştu."
      console.error(ex)
    }
  }else{
    if(response?.Success){
      _variant = "success"
      _icon = "CheckCircleIcon"
      _message = response?.Message
    }else if(!response?.Success){
      _variant = "danger"
      _icon = "AlertCircleIcon"
      _message = response?.Message
    }else{
      _variant = "danger"
      _icon = "AlertCircleIcon"
      _message = "Bilinmeyen bir hata oluştu."
    }
  }

  if(!ex && ex?.response?.status != 400){
    vue?.$toast({
      component: ToastificationContent,
      props: {
        title: _message,
        icon: _icon,
        variant: _variant,
      },
    })
  }
}