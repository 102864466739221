import store from '@/store'
import { RoleR } from './signalr/role';
import { UserActiveR } from './signalr/user-active';
import { NotifyR } from './signalr/notify';


export default {
    get Development(){
        return store.state.system.development
    },
    BaseURL:{
        get URL() {
            return store.state.system.url;
        },
        set URL(value) {
            store.commit('system/setUrl', value);
        },
        Define(){
            if(process.env.NODE_ENV === 'development'){
                this.URL = 'https://localhost:7092'
                store.commit('system/setDevelopment', true)
            }else{
                this.URL = 'https://vml.visnemadencilik.com/api'
                store.commit('system/setDevelopment', false)
            }
        }
    },
    SignalR:{
        get role() { return new RoleR() },
        get userActive() { return new UserActiveR() },
        get notify() { return new NotifyR() },
        GetInstances(){
            return {
                role: this.role,
                userActive: this.userActive,
                notify: this.notify,
            }
        },
    }
}