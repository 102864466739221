import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Dynamic from '@/components/Dynamic.vue'
import VTable from '@/components/VTable.vue'
import VExecuter from '@/components/VExecuter.vue'
import VValidator from '@/components/VValidator.vue'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss';
import VSelectList from '@/components/VSelectList.vue'
import VDatePicker from '@/components/VDatePicker.vue'

vSelect.props.components.default = () => ({
    Deselect: {
        render: createElement => createElement('span',{class:"material-icons-round text-danger"}, 'close'),
    },
    OpenIndicator: {
        render: createElement => createElement('span',{class:"material-icons-round"}, 'expand_more'),
    },
})

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component("v-select",vSelect)
Vue.component("v-select-list",VSelectList)
Vue.component("v-date-picker",VDatePicker)
Vue.component("dynamic", Dynamic)
Vue.component("v-table",VTable)
Vue.component("v-executer",VExecuter)
Vue.component("v-validator",VValidator)
