export default{
    namespaced: true,
    state:{
        notifications:[],
    },
    mutations:{
        setNotifications(state,value){
            state.notifications = value
        }
    },
    getters:{},
    setters:{}
}