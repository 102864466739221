import axios from 'axios'

export const SystemParameterApi = {
    async List(){
        const response = await axios.get(`/api/systemparameters/list`)
        return response
    },
    async Update(data){
        const response = await axios.post(`/api/systemparameters/update`,data)
        return response
    },
    async Get(data){
        const response = await axios.get(`/api/systemparameters/get/${data}`)
        return response
    },
    async Bank(data){
        const response = await axios.get(`/api/systemparameters/bankparameters/get/${data}`)
        return response
    },
    async BankUpdate(data){
        const response = await axios.post(`/api/systemparameters/bankparameters/update`,data)
        return response
    },
    async SMS(data){
        const response = await axios.get(`/api/systemparameters/smsparameters/get/${data}`)
        return response
    },
    async SMSUpdate(data){
        const response = await axios.post(`/api/systemparameters/smsparameters/update`,data)
        return response
    },
    async MailSettingUpdate(data){
        const response = await axios.post(`/api/systemparameters/mailsettings/update`,data)
        return response
    },
    async MailSettingList(){
        const response = await axios.get(`/api/systemparameters/mailsettings/get/list`)
        return response
    },
    async BankInstallments(){
        const response = await axios.get(`/api/systemparameters/bankinstallments/get`)
        return response
    },
    async CreateBankInstallment(data){
        const response = await axios.post(`/api/systemparameters/bankinstallments/create`,data)
        return response
    },
    async DeleteBankInstallment(data){
        const response = await axios.post(`/api/systemparameters/bankinstallments/delete/${data}`)
        return response
    },
    async BankInstallmentsCampaign(){
        const response = await axios.get(`/api/systemparameters/bankinstallmentscampaign/get`)
        return response
    },
    async CreateBankInstallmentCampaign(data){
        const response = await axios.post(`/api/systemparameters/bankinstallmentscampaign/create`,data)
        return response
    },
    async DeleteBankInstallmentCampaign(data){
        const response = await axios.post(`/api/systemparameters/bankinstallmentscampaign/delete/${data}`)
        return response
    }
}