export default{
    namespaced: true,
    state:{
        parameters:[]
    },
    mutations:{
        setParameters(state,value){
            state.parameters = value;
        },
    },
    getters:{},
    setters:{}
}