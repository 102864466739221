import Auth from '@/utilities/auth'
import System from '..'
const signalR = require('@microsoft/signalr')
import router from '@/router'

export class UserActiveR{
    connection = null

    constructor(){
        this.Configure()
    }

    Configure(){
        if(Auth.user?.Id){
            this.Connect().then(()=> this.Listen())
        }
    }

    Connect(){
        return new Promise((resolve,reject)=>{
            this.connection = new signalR.HubConnectionBuilder().withUrl(System.BaseURL.URL + "/useractivestatushub",{ 
                headers:{ UserId : Auth.user?.Id || null },
                transport: signalR.HttpTransportType.LongPolling 
            }).build()
            this.connection.start().then(function(s){ 
                System.Development ? console.log('SignalR Connected For User Active') : null
                resolve() 
            }).catch(function (err) { reject(err) })
        })
    }

    Listen(){
        this.connection.on('updateUserActiveStatus', (c)=> { 
            if( c == 'true' ){
                Auth.isAuthenticated = false
                Auth.user = {}
                router.push({ name: 'login' })
            }
        })
    }
}