import Modal from "@/utilities/modal"

export default function Confirm(vue,{title,text,cancel="Vazgeç",ok="Tamam"}){
    return new Promise((resolve,reject)=>{
        document.activeElement.blur();
        Modal.isModalOpen = true
        vue.$bvModal.msgBoxConfirm(
            text, {
            title:title,
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            cancelVariant:'dark',
            okTitle: ok,
            cancelTitle:cancel,
            footerClass: 'p-05',
            hideHeaderClose: false,
        })
        .then(value => { resolve(value) })
        .catch(err => { reject(err) })
        
        vue.$root.$on('bv::modal::hide', () => {
            Modal.isModalOpen = false
        })
    })
}