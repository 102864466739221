import Auth from '@/utilities/auth'
import System from '../'
const signalR = require('@microsoft/signalr')
import router from '@/router'

export class RoleR{
    connection = null

    constructor(){
        this.Configure()
    }

    Configure(){
        if(Auth.user?.Id){
            this.Connect().then(()=> this.Listen())
        }
    }

    Connect(){
        return new Promise((resolve,reject)=>{
            this.connection = new signalR.HubConnectionBuilder().withUrl(System.BaseURL.URL + "/userrolehub",{ 
                headers:{ UserId : Auth.user?.Id || null },
                transport: signalR.HttpTransportType.LongPolling 
            }).build()
            this.connection.start().then(function(s){ 
                System.Development ? console.log('SignalR Connected For Role') : null
                resolve() 
            }).catch(function (err) { reject(err) })
        })
    }

    Listen(){
        this.connection.on('updateUserRole', (c)=> { 
            Auth.roles = JSON.parse(c)
            let currentRouteRole = router.currentRoute?.meta?.requiresRole || []
            if(currentRouteRole.length > 0){
                Auth.hasRole(currentRouteRole) ? null : router.push({ name: 'error-404' })
            }
        })
    }
}