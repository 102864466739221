<template>
    <b-button :disabled="loading || disabled" :variant="variant" :class="fullWidth ? 'd-flex justify-content-center w-100': 'd-inline-flex'" class="d-inline-flex align-items-center" @click="action ? action() : null">
        <b-spinner class="mr-1" small v-if="loading"></b-spinner>
        <template v-if="hasSlot">
            <slot></slot>
        </template>
        <span v-else-if="text">{{text}}</span>
        <span v-else>{{editing ? 'Güncelle' : 'Oluştur'}}</span>
    </b-button>
</template>

<script>
import { BButton,BSpinner } from 'bootstrap-vue'
export default {
    components:{
        BButton,
        BSpinner  
    },
    props:{
        action:Function,
        loading:Boolean,
        editing:Boolean,
        disabled:Boolean,
        text:String,
        fullWidth:Boolean,
        variant:{
            type:String,
            default:'primary'
        },
    },
    computed:{
        hasSlot(){
            return !!this.$slots.default
        }
    }
}
</script>

<style>

</style>