import axios from 'axios'
import { serialize } from 'object-to-formdata'
import objectPath from 'object-path'
import randomatic from 'randomatic'

export default{
    async ToFormData(object,files=[]){
        return this.ConvertToBlob(object,files).then(object => {
            return serialize(object,{indices: true,dotsForObjectNotation: true,nullsAsUndefineds:true})
        })
    },
    PromiseEach(items, fn){
        return items.reduce(function (promise, item) {
            return promise.then(function () {
                return fn(item)
            })
        }, Promise.resolve())
    },
    ConvertToBlob(object,files){
        return new Promise((resolveOut) => {
            if(files.length){
                this.PromiseEach(files,(file) => new Promise((resolve,reject)=>{
                    let url = objectPath.get(object,file)
                    this.UrlToFile(url || null).then(blob => {
                        objectPath.set(object,file,blob)
                        resolve(object)
                    })
                })).then((result)=>{ 
                    resolveOut(result)
                })
            }else resolveOut(object)
        })
    },
    UrlToFile(value){
        return new Promise((resolve)=>{
            if(typeof value === "string"){
                let ext = value.split('.').pop()
                let name = [["file",randomatic('A0',15)].join("-"),ext].join(".")
                axios.get(value,{ responseType: 'blob' }).then(response => {
                    const file = new File([response.data], name);
                    resolve(file)
                })
            }else resolve(value)
        })
    },
    ConvertNestedDataToSingleData(data,key,id = "Id",parentId = "ParentId",keys = []){
        const parentChildList = []
        let group = data.filter(f => f[parentId] === null)
        group.forEach(item => {
            let child = data.filter(w => w[parentId] === item[id])
            if (child.length > 0) {
                item['children'] = child
                this.Nested(data, item, id, parentId)
                parentChildList.push(item)
            } else {
                parentChildList.push(item)
            }
        })
        return this.ReturnPath(parentChildList, key, "", id,keys)
    },
    Nested(allData, activeItem, id, parentId){
        let child = allData.filter(w => w[parentId] === activeItem[id]);
        if (child.length > 0) {
            child.forEach(item => {
                let child = allData.filter((w) => w[parentId] === item[id]);
                if (child.length > 0) {
                    item['children'] = child;
                    this.Nested(allData, item, id, parentId);
                }
            });
        }
    },
    ReturnPath(obj, property, path='', id='',keys = []){
        let paths = [];
        for (let k in obj) {
            if (typeof (obj[k]) === 'object') {
                paths.push(...this.ReturnPath(obj[k], property, path, id,keys));
            } else if (k === property) {
                path += (path ? " -> " : "") + obj[k];
                let object = { Name: path, Id: obj[id] }
                keys.forEach(k=> Object.assign(object,{ [k]:obj[k] }))
                paths.push(object)
            }
        }
        return paths;
    },
    Wait(ms){ return new Promise(resolve => setTimeout(resolve, ms)) },
    DownloadFile(blob,name){
        var fileURL = window.URL.createObjectURL(new Blob([blob]));
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', name)
        document.body.appendChild(fileLink)
        fileLink.click()
        fileLink.remove()
    },
    DownloadFile(blob,name){
        var fileURL = window.URL.createObjectURL(new Blob([blob]));
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', name)
        document.body.appendChild(fileLink)
        fileLink.click()
        fileLink.remove()
    },
    NewTab(url){
        var fileLink = document.createElement('a')
        fileLink.href = url
        fileLink.setAttribute('target', '_blank')
        document.body.appendChild(fileLink)
        fileLink.click()
        fileLink.remove()
    },
    
    ToEng(str){
        return  String(str).replace('Ğ','g')
                .replace('Ü','u')
                .replace('Ş','s')
                .replace('I','i')
                .replace('İ','i')
                .replace('Ö','o')
                .replace('Ç','c')
                .replace('ğ','g')
                .replace('ü','u')
                .replace('ş','s')
                .replace('ı','i')
                .replace('ö','o')
                .replace('ç','c')
    },
    async GetBlobFile(url){
        const response = await axios.get(url,{responseType: 'blob'})
        return response
    },
    ConvertPascalCaseSingleWorld(str){
        if(typeof str === 'string'){
            return str.toLocaleLowerCase().charAt(0).toUpperCase() + str.substring(1).toLocaleLowerCase()
        }else return str
    },
    ApiFileName(url){
        return url.replace(/^(.*?)_/,'')
    },
    RemoveSpace(str){
        return str.replace(/\s/g, '')
    }
}