import store from '@/store'

export default{
    get isModalOpen(){
        return store.state.modal.isModalOpen
    },
    set isModalOpen(value){
        store.commit('modal/setIsModalOpen', value)
    },
    show(vue,id){
        document.activeElement.blur();
        vue.$nextTick(()=>{ vue.$bvModal.show(id) })
        this.isModalOpen = true
        vue.$root.$on('bv::modal::hide', () => {
            this.isModalOpen = false
        })
    },
    hide(vue,id){
        document.activeElement.blur();
        vue.$nextTick(()=>{ vue.$bvModal.hide(id) })
        this.isModalOpen = false
    }
}