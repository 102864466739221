import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function alert(vue,message,icon,variant){
    vue?.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: icon,
          variant: variant,
        },
    })
}