import Auth from '@/utilities/auth'
import System from '../'
const signalR = require('@microsoft/signalr')
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'

export class NotifyR{
    connection = null
    vue = null

    constructor(){
        this.Configure()
    }

    Configure(vue){
        this.vue = vue
        if(Auth.user?.Id){
            this.Connect().then(()=> this.Listen())
        }
    }

    Connect(){
        return new Promise((resolve,reject)=>{
            this.connection = new signalR.HubConnectionBuilder().withUrl(System.BaseURL.URL + "/notifyhub",{ 
                headers:{ UserId : Auth.user?.Id || null },
                transport: signalR.HttpTransportType.LongPolling 
            }).build()
            this.connection.start().then(function(s){ 
                System.Development ? console.log('SignalR Connected For Notify ') : null
                resolve() 
            }).catch(function (err) { reject(err) })
        })
    }

    Listen(){
        this.connection.on('userNotify', (c)=> { 
            Vue.$toast({
                component: ToastificationContent,
                props: {
                    title: JSON.parse(c)?.Title,
                    text: JSON.parse(c)?.Content,
                    icon: 'BellIcon',
                    variant: 'primary',
                },
            },{ timeout:10000, hideProgressBar:true})
        })
    }
}