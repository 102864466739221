import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '@/utilities/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/HomeView.vue'),
      meta: {
        requiresAuth: true,
        icon:'home',
        pageTitle: 'Ana Sayfa',
        
      },
    },
    {
      path: '/Auth/Login',
      name: 'login',
      component: () => import('@/views/auth/LoginView.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path:'/SystemManagement/User',
      name:'user',
      component: () => import('@/views/user/UserView.vue'),
      meta: {
        requiresRole:["user.operations"],
        requiresAuth: true,
        icon: 'people',
        pageTitle: 'Sistem Yönetimi',
        breadcrumb: [
          {
            text: 'Kullanıcılar',
            active: true,
          }
        ],
      },
    },
    {
      path:'/SystemManagement/Role',
      name:'role',
      component: () => import('@/views/role/RoleView.vue'),
      meta: {
        requiresRole:["role.operations"],
        requiresAuth: true,
        icon: 'settings_accessibility',
        pageTitle: 'Rol Yönetimi',
        breadcrumb: [
          {
            text: 'Rol Yönetimi',
            active: true,
          }
        ],
      },
    },
    {
      path:'/SystemManagement/Material',
      name:'material',
      component: () => import('@/views/material/MaterialView.vue'),
      meta: {
        requiresRole:["product.operations"],
        requiresAuth: true,
        icon: 'shopping_basket',
        pageTitle: 'Ürün İşlemleri',
        breadcrumb: [
          {
            text: 'Ürün İşlemleri',
            active: true,
          }
        ],
      },
    },
    {
      path:'/SystemManagement/VehicleType',
      name:'vehicle-type',
      component: () => import('@/views/vehicle-type/VehicleTypeView.vue'),
      meta: {
        requiresRole:["vehicle.type.operations"],
        requiresAuth: true,
        icon: 'local_shipping',
        pageTitle: 'Araç Tipi İşlemleri',
        breadcrumb: [
          {
            text: 'Araç Tipi İşlemleri',
            active: true,
          }
        ],
      },
    },
    {
      path:'/SystemManagement/LocationType',
      name:'location-type',
      component: () => import('@/views/location-type/LocationTypeView.vue'),
      meta: {
        //requiresRole:["ROLE_OPERATIONS"],
        requiresAuth: true,
        icon: 'edit_location',
        pageTitle: 'Lokasyon Tipi İşlemleri',
        breadcrumb: [
          {
            text: 'Lokasyon Tipi İşlemleri',
            active: true,
          }
        ],
      },
    },
    {
      path:'/SystemManagement/TransferRegion',
      name:'transfer-region',
      component: () => import('@/views/transfer-region/TransferRegionView.vue'),
      meta: {
        requiresRole:["transfer.region.operations"],
        requiresAuth: true,
        icon: 'edit_location',
        pageTitle: 'Transfer Bölgesi İşlemleri',
        breadcrumb: [
          {
            text: 'Transfer Bölgesi İşlemleri',
            active: true,
          }
        ],
      },
    },
    {
      path:'/SystemParameter',
      name:'system-parameter',
      component: () => import('@/views/system-parameter/SystemParameterView.vue'),
      meta: {
        requiresRole:["system.parameter.operations"],
        requiresAuth: true,
        icon: 'dns',
        pageTitle: 'Sistem Parametreleri',
        breadcrumb: [
          {
            text: 'Sistem Parametreleri',
            active: true,
          }
        ],
      },
    },
    {
      path:'/Voyage',
      name:'voyage',
      component: () => import('@/views/voyage/VoyageView.vue'),
      meta: {
        requiresRole:["voyage.operations"],
        requiresAuth: true,
        icon: 'route',
        pageTitle: 'Seferler',
        breadcrumb: [
          {
            text: 'Sefer İşlemleri',
            active: true,
          },
          {
            text: 'Seferler',
            active: true,
          }
        ],
      },
    },
    // {
    //   path:'/VoyageReport',
    //   name:'voyage-report',
    //   component: () => import('@/views/voyage-report/VoyageReportView.vue'),
    //   meta: {
    //     requiresRole:["voyage.report.operations"],
    //     requiresAuth: true,
    //     icon: 'fork_left',
    //     pageTitle: 'Sefer Raporu',
    //     breadcrumb: [
    //       {
    //         text: 'Sefer İşlemleri',
    //         active: true,
    //       },
    //       {
    //         text: 'Sefer Raporu',
    //         active: true,
    //       }
    //     ],
    //   },
    // },
    {
      path:'/VoyageReport',
      name:'voyage-report',
      component: () => import('@/views/voyage-report-new/VoyageReportView.vue'),
      meta: {
        requiresRole:["voyage.report.operations"],
        requiresAuth: true,
        icon: 'fork_left',
        pageTitle: 'Sefer Raporu',
        breadcrumb: [
          {
            text: 'Sefer İşlemleri',
            active: true,
          },
          {
            text: 'Sefer Raporu',
            active: true,
          }
        ],
      },
    },
    {
      path:'/Cari',
      name:'cari',
      component: () => import('@/views/cari/CariView.vue'),
      meta: {
        requiresRole:["cari.operations"],
        requiresAuth: true,
        icon: 'groups',
        pageTitle: 'Cari İşlemleri',
        breadcrumb: [
          {
            text: 'Cari İşlemleri',
            active: true,
          }
        ],
      },
    },
    {
      path:'/WaybillGroup',
      name:'waybill-operations',
      component: () => import('@/views/waybill-group/WaybillGroupView.vue'),
      meta: {
        requiresRole:["irsaliye.operations"],
        requiresAuth: true,
        icon: 'fact_check',
        pageTitle: 'İrsaliye İşlemleri',
        breadcrumb: [
          {
            text: 'İrsaliye İşlemleri',
            active: true,
          }
        ],
      },
    },
    {
      path:'/ReceiptWaybillGroup',
      name:'receipt-waybill-operations',
      component: () => import('@/views/irsaliye/alis-irsaliye/AlisIrsaliyeView.vue'),
      meta: {
        requiresRole:["irsaliye.operations"],
        requiresAuth: true,
        icon: 'fact_check',
        pageTitle: 'Alış İrsaliyesi İşlemleri',
        breadcrumb: [
          {
            text: 'Alış İrsaliyesi İşlemleri',
            active: true,
          }
        ],
      },
    },
    {
      path:'/WaybillGroup/Customer/Detail/:id/',
      name:'waybill-detail-customer',
      component: () => import('@/views/waybill-group/WaybillGroupCustomerDetailView.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path:'/WaybillGroup/Detail/:id',
      name:'waybill-detail',
      component: () => import('@/views/waybill-group/WaybillGroupDetailView.vue'),
      meta: {
        requiresRole:["irsaliye.operations"],
        requiresAuth: true,
        icon: 'fact_check',
        pageTitle: 'İrsaliye Grubu Sefer İşlemleri',
        breadcrumb: [
          {
            text: 'İrsaliye İşlemleri',
            active: false,
            to:'/WaybillGroup'
          },
          {
            text: 'İrsaliye Grubu Sefer İşlemleri',
            active: true,
          }
        ],
      },
    },
    {
      path:'/Agreement',
      name:'agreement',
      component: () => import('@/views/agreement/AgreementView.vue'),
      meta: {
        requiresRole:["agreement.operations"],
        requiresAuth: true,
        icon: 'handshake',
        pageTitle: 'Sözleşmeler',
        breadcrumb: [
          {
            text: 'Sözleşmeler',
            active: true,
          }
        ],
      },
    },
    {
      path:'/Agreement/Detail/:id',
      name:'agreement-detail',
      component: () => import('@/views/agreement/AgreementDetailView.vue'),
      meta: {
        requiresRole:["agreement.operations"],
        requiresAuth: true,
        icon: 'handshake',
        pageTitle: 'Sözleşme Detayı',
        breadcrumb: [
          {
            text: 'Sözleşmeler',
            active: false,
            to:'/Agreement'
          },
          {
            text: 'Sözleşme Detayı',
            active: true,
          }
        ],
      },
    },
    {
      path:'/Agreement/PriceChangeHistory/:id',
      name:'agreement-price-history',
      component: () => import('@/views/agreement/AgreementPriceChangeHistory.vue'),
      meta: {
        requiresRole:["agreement.operations"],
        requiresAuth: true,
        icon: 'handshake',
        pageTitle: 'Sözleşme Oransal Fiyat Artışları',
        breadcrumb: [
          {
            text: 'Sözleşmeler',
            active: false,
            to:'/Agreement'
          },
          {
            text: 'Sözleşme Oransal Fiyat Artışları',
            active: true,
          }
        ],
      },
    },
    {
      path:'/Agreement/Create',
      name:'agreement-create',
      component: () => import('@/views/agreement/AgreementCreateView.vue'),
      meta: {
        requiresRole:["agreement.operations"],
        requiresAuth: true,
        icon: 'handshake',
        pageTitle: 'Sözleşme Oluştur',
        breadcrumb: [
          {
            text: 'Sözleşmeler',
            active: false,
            to:'/Agreement'
          },
          {
            text: 'Sözleşme Oluştur',
            active: true,
          }
        ],
      },
    },
    {
      path:'/OutAgreement',
      name:'out-agreement',
      component: () => import('@/views/out-agreement/AgreementView.vue'),
      meta: {
        requiresRole:["agreement.operations"],
        requiresAuth: true,
        icon: 'handshake',
        pageTitle: 'Dış Anlaşmalar',
        breadcrumb: [
          {
            text: 'Dış Anlaşmalar',
            active: true,
          }
        ],
      },
    },
    {
      path:'/OutAgreement/Detail/:id',
      name:'out-agreement-detail',
      component: () => import('@/views/out-agreement/AgreementDetailView.vue'),
      meta: {
        requiresRole:["agreement.operations"],
        requiresAuth: true,
        icon: 'handshake',
        pageTitle: 'Dış Anlaşma Detayı',
        breadcrumb: [
          {
            text: 'Dış Anlaşmalar',
            active: false,
            to:'/OutAgreement'
          },
          {
            text: 'Dış Anlaşma Detayı',
            active: true,
          }
        ],
      },
    },
    {
      path:'/OutAgreement/PriceChangeHistory/:id',
      name:'out-agreement-price-history',
      component: () => import('@/views/out-agreement/AgreementPriceChangeHistory.vue'),
      meta: {
        requiresRole:["agreement.operations"],
        requiresAuth: true,
        icon: 'handshake',
        pageTitle: 'Dış Anlaşmalar Oransal Fiyat Artışları',
        breadcrumb: [
          {
            text: 'Dış Anlaşmalar',
            active: false,
            to:'/OutAgreement'
          },
          {
            text: 'Dış Anlaşmalar Oransal Fiyat Artışları',
            active: true,
          }
        ],
      },
    },
    {
      path:'/OutAgreement/Create',
      name:'out-agreement-create',
      component: () => import('@/views/out-agreement/AgreementCreateView.vue'),
      meta: {
        requiresRole:["agreement.operations"],
        requiresAuth: true,
        icon: 'handshake',
        pageTitle: 'Dış Anlaşma Oluştur',
        breadcrumb: [
          {
            text: 'Dış Anlaşmalar',
            active: false,
            to:'/OutAgreement'
          },
          {
            text: 'Dış Anlaşma Oluştur',
            active: true,
          }
        ],
      },
    },
    {
      path:'/Offer/Request/Create',
      name:'offer-request-create',
      component: () => import('@/views/offer/offer-request/OfferRequestCreateView.vue'),
      meta: {
        requiresRole:["offer.operations"],
        requiresAuth: true,
        icon: 'quiz',
        pageTitle: 'Teklif İste',
        breadcrumb: [
          {
            text: 'Teklif İşlemleri',
            active: false,
            to:'/Offer/Request'
          },
          {
            text: 'Teklif İste',
            active: true,
          }
        ],
      },
    },
    {
      path:'/Offer/Request/Update/:id',
      name:'offer-request-update',
      component: () => import('@/views/offer/offer-request/OfferRequestUpdateView.vue'),
      meta: {
        requiresRole:["offer.operations"],
        requiresAuth: true,
        icon: 'quiz',
        pageTitle: 'Teklif Güncelle',
        breadcrumb: [
          {
            text: 'Teklif İşlemleri',
            active: false,
            to:'/Offer/Request'
          },
          {
            text: 'Teklif Güncelle',
            active: true,
          }
        ],
      },
    },
    {
      path:'/Offer/Request',
      name:'offer-request',
      component: () => import('@/views/offer/offer-request/OfferRequestView.vue'),
      meta: {
        requiresRole:["offer.operations"],
        requiresAuth: true,
        icon: 'quiz',
        pageTitle: 'Teklifler',
        breadcrumb: [
          {
            text: 'Teklif İşlemleri',
            active: false,
          }
        ],
      },
    },
    {
      path:'/ReceiptOperations',
      name:'receipt-operation',
      component: () => import('@/views/receipt-operations/ReceiptOperationsView.vue'),
      meta: {
        requiresRole:["receipt.operations"],
        requiresAuth: true,
        icon: 'quiz',
        pageTitle: 'Fatura İşlemleri',
        breadcrumb: [
          {
            text: 'Fatura İşlemleri',
            active: false,
          }
        ],
      },
    },
    {
      path: '/Offer/Request/Customer/:id',
      name: 'offer-request-customer',
      component: () => import('@/views/offer/offer-request-customer/OfferRequestCustomerView.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/Profile',
      name: 'profile',
      component: () => import('@/views/profile/ProfileView.vue'),
      meta: {
        requiresAuth: true,
        icon: 'account_circle',
        pageTitle: 'Profil Yönetimi',
        breadcrumb: [
          {
            text: 'Profil Yönetimi',
            active: true,
          }
        ],
      },
    },
    {
      path: '/Activation/:code',
      name: 'activate',
      component: () => import('@/views/auth/ActivateView.vue'),
      meta: {
        layout: 'full',
        contentClass:'d-flex align-items-center justify-content-center h-100'
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (Auth.isAuthenticated) {
      if(to.matched.some(record => record.meta.requiresRole)) {
        if(to.meta.requiresRole?.length > 0){
          if(Auth.hasRole(to.meta.requiresRole)) {
            next()
          } else router.push({ name: 'error-404' })
        }else next()
      } else next()
    } else router.push({ name: 'login',query:{ redirect:to.path } })
  } else next()
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
