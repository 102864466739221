import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import confirm from './config/confirm.config'
import result from './config/result.config'
import alert from './config/alert.config'
import 'material-icons/iconfont/outlined.css';
import 'material-icons/iconfont/round.css';
import modal from './utilities/modal'
import objectPath from "object-path"
import System from './utilities/system'
import VueMask from 'v-mask'
import Mask from '@/utilities/mask'
import {imageError} from '@/directives/image-error'
import createAxiosResponseInterceptor from './config/axios.config'
import Notifications from './utilities/notifications'
import Parameters from './utilities/parameters'


import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import axios from 'axios'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Mask
Vue.use(VueMask);
Vue.prototype.$mask = Mask

//Image Error
Vue.directive("image-error",imageError)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false


//Define Url
System.BaseURL.Define()

// Confirm Box
Vue.prototype.$confirm = function(title,text,ok="Tamam",cancel = "Vazgeç"){
    return confirm(this,{title:title,text:text,cancel:cancel,ok:ok})
}

//Request Result
Vue.prototype.$result = function(res,ex){
    return result(res,this,ex)
}

//Show Any Alert Manually
Vue.prototype.$alert = function(message,icon,variant){
    return alert(this,message,icon,variant)
}

//Show Modal
Vue.prototype.$showModal = function(id){
    modal.show(this,id)
}

//Hide Modal
Vue.prototype.$hideModal = function(id){
    modal.hide(this,id)
}

//Safe Value
Vue.prototype.$safe = function(object,path){
    return objectPath.get(object,path)
}

//SignalR Instances
//Vue.prototype.$signalr = System.SignalR.GetInstances()

//Axios Setup 
axios.defaults.baseURL = System.BaseURL.URL
createAxiosResponseInterceptor()

//System Params
Parameters.loadParameters()
Vue.prototype.$param = function(key){
    return Parameters.getParam(key)
}

//Notifications
//Notifications.getNotifications()

//VUE
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
