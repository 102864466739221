import store from '@/store'
import NotificationApi from '@/api/notifications'

export default{
    get notifications(){
        return store.state.notifications.notifications
    },
    set notifications(value){
        store.commit('notifications/setNotifications', value)
    },
    getNotifications(){
        // NotificationApi.GetLast10().then(res=>{
        //     this.notifications = res.data.Data
        // })
    }
}