<template>
    <date-picker v-bind="[$props,$attrs]" v-on="$listeners" format="DD.MM.YYYY" value-type="YYYY-MM-DD">
        <template #icon-calendar>
            <span class="material-icons-outlined">
            event
            </span>
        </template>
        <template #icon-clear>
            <span class="material-icons-outlined">
            clear
            </span>
        </template>
    </date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import '@core/scss/vue/libs/date-picker.scss'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/tr';
export default {
    components:{
        DatePicker
    },
}
</script>

<style lang="scss">

</style>