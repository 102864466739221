import store from '@/store'

export default {
    get tables(){
        return store.state.table.tables
    },
    getTable(table){
        return this.tables.find(t=> t.name == table) || null
    },
    addTable(table){
        store.commit('table/addTable', table)
    },
    updateTableField(tableName,field,value){
        store.commit('table/updateTableField',{name:tableName,field,value})
    },
    getTableField(table,field){
        const _table = this.getTable(table)
        return _table ? _table[field] || null : null
    },
}