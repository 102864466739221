import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import modal from './modal'
import system from './system'
import notifications from './notifications'
import parameters from './parameters'

import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";
import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  plugins:[
    createPersistedState(),
    // createPersistedState({
    //   storage: {
    //     getItem: key => ls.get(key),
    //     setItem: (key, value) => ls.set(key, value),
    //     removeItem: key => ls.remove(key)
    //   }
    // }),
    createMutationsSharer({predicate: [
      "appConfig/UPDATE_SKIN",
      "system/setGStatus",
      "auth/SetUserAuthenticate",
      "auth/SetUser",
      "auth/SetIsAdmin",
      "auth/SetRoles",
      "auth/RefreshData",
      "notifications/setNotifications",
      "parameters/setParameters",
    ]})
  ],
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    modal,
    system,
    notifications,
    parameters
  },
  strict: process.env.DEV,
})
