export const imageError = {
    bind: function (el, binding, vnode) {
        let event = function (e) {
            e.preventDefault();
            if(binding.value){
                e.target.src = binding.value
            }else{
                e.target.src = require("@/assets/images/illustrations/no-image.svg")
            }
        }
        vnode.elm.dispatchEvent(new CustomEvent('error'))
        el.addEventListener("error", event)
    },
}