export default{
    namespaced: true,
    state:{
        url:null,
        development:false,
    },
    mutations:{
        setUrl(state,value){
            state.url = value;
        },
        setDevelopment(state,value){
            state.development = value;
        },
    },
    getters:{},
    setters:{}
}