<template>
    <v-select :filter="search" v-bind="[$props,$attrs]" v-on="$listeners">
        <slot></slot>
        <template v-if="$attrs['loading']" #spinner>
            <b-spinner small class="mr-50" variant="primary" v-show="$attrs['loading']"></b-spinner>
        </template>
        <template v-if="$attrs['img']" #option="option">
            <div v-if="option[$attrs['img']]" class="option-image">
                <div class="option-image-wrapper">
                    <img v-image-error :src="option[$attrs['img']]">
                </div>
                {{option[$attrs.label]}}
            </div>
        </template>
        <template #no-options="{ search, searching }">
            <template v-if="searching">'<b>{{ search }}</b>' için bir sonuç bulunamadı.</template>
            <template v-else>Herhangi bir veri bulunamadı.</template>
        </template>
    </v-select>
</template>

<script>
import {BSpinner} from 'bootstrap-vue'
import Additional from '@/utilities/additional'
export default {
    components:{
        BSpinner,
    },
    methods:{
        search(options,search){
            return search ? options.filter(o=> Additional.ToEng(o[this.$attrs['label']]).toLowerCase().includes(Additional.ToEng(search).toLowerCase())) : options 
        }
    }
}
</script>