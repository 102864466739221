import axios from 'axios'
import { AuthApi } from '../api/auth'
import moment from 'moment'
import router from '@/router'
import Auth from '@/utilities/auth'

axios.defaults.headers.common['Authorization'] = 'Bearer ' + Auth.token

export default function createAxiosResponseInterceptor (){
	const interceptor = axios.interceptors.response.use(
		response => response,
		async error => {
			
			if(error.response){
				if(error.response.status == 401){

					if(!Auth.refreshToken || moment().isAfter(moment(Auth.refreshTokenExpireTime || null))){
						router.push({ name: 'login',query:{ redirect:router.currentRoute.path } })
						return Promise.reject(error)
					}

					if(moment().isAfter(moment(Auth.tokenExpireTime || null))){
						axios.interceptors.response.eject(interceptor)
							return AuthApi.RefreshToken(Auth.refreshToken).then(response => {
									if(response.data.Success){
										Auth.isAuthenticated = true
										Auth.refreshData(response.data.Data.Token,response.data.Data.RefreshToken,response.data.Data.RefreshTokenExpireTime)
										error.response.config.headers['Authorization'] = 'Bearer ' + response.data.Data.Token
										return axios(error.response.config)
									}else{
										router.push({ name: 'login',query:{ redirect:router.currentRoute.path } })
										return Promise.reject(error)
									}
								}).catch(error => {
									router.push({ name: 'login',query:{ redirect:router.currentRoute.path } })
									return Promise.reject(error)
								}).finally(createAxiosResponseInterceptor)
					}else return Promise.reject(error)

				} return Promise.reject(error)
			}else return Promise.reject(error)

		}
	)
}
