import store from '@/store'
import { SystemParameterApi } from '@/api/system-parameter';

export default{
    get parameters(){
        return store.state.parameters.parameters
    },
    set parameters(val){
        store.commit('parameters/setParameters', val);
    },
    loadParameters(){
        return new Promise((resolve)=>{
            SystemParameterApi.List().then(res=>{
                if(res.data.Success) this.parameters = res.data.Data
                resolve(true)
            }).catch(()=>resolve(true))
        })
    },
    getParam(key){
        return this.parameters.find(p=>p.Key == key)?.Value || null 
    }
}