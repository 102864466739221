<template>
    <div class="v-table-container">
        <b-overlay :show="loading" opacity="0.2" rounded="sm" :class="overlayClass">
            <div v-if="addCallback || settings || search || c_additionalActions"  class="p-1">
                <div class="row align-items-center" :class="addCallback || c_additionalActions || c_hasFilters ? 'justify-content-between' : 'justify-content-end'">
                    <div v-if="addCallback || c_hasFilters" class="col-auto mb-1 mb-md-0">
                        <div class="row">
                            <div v-if="addCallback" class="col-auto mb-1 mb-md-0">
                                <button class="btn btn-primary btn-icon rounded-circle text-nowrap waves-effect" v-on:click="addCallback">
                                    <feather-icon icon="PlusIcon" size="16"></feather-icon>
                                </button>
                            </div>
                            <div v-if="c_hasFilters" class="col-auto mb-1 mb-md-0">
                                <button v-if="c_hasFilters" class="btn btn-dark shadow-none" v-on:click="showFilters = !showFilters">
                                    <feather-icon icon="FilterIcon" class="mr-50"></feather-icon>
                                    Filtreler
                                    <feather-icon class="ml-25" :icon="showFilters ? 'ChevronUpIcon':'ChevronDownIcon'"></feather-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="c_additionalActions" class="col mb-1 mb-md-0"><slot name="additional-actions"></slot></div>
                    <div v-if="settings || search || excelCallback || c_hasInformation" :class="search ? 'col-md-4 col-xl-3' : 'col-md-auto'">
                        <div class="d-flex align-items-center">
                            <div v-if="c_hasInformation" id="v-table-info" class="v-table-info mr-25 ml-25 text-info rounded">
                                <feather-icon icon="InfoIcon"></feather-icon>
                            </div>
                            <b-dropdown v-if="excelCallback" v-b-tooltip.noninteractive.hover.bottom title="Dışa Aktar"  size="sm" no-caret dropleft variant="flat-success" toggle-class="btn-icon" class="mr-25 ml-25">
                                <template #button-content>
                                    <feather-icon icon="ArrowDownCircleIcon" size="16"></feather-icon>
                                </template>
                                <div class="pr-50 pl-50">
                                    <div class="d-flex align-items-center c-pointer"  v-on:click="excelCallback">
                                        <div class="w-20 d-flex align-items-center justify-content-center text-success">
                                            <b-spinner small v-if="excelLoading"></b-spinner>
                                            <b-icon-file-earmark-spreadsheet-fill v-else></b-icon-file-earmark-spreadsheet-fill>
                                        </div>
                                        <span class="ml-25">Excel</span>
                                    </div>
                                </div>
                            </b-dropdown>
                            <b-dropdown v-b-tooltip.noninteractive.hover.bottom title="Sütun Ayarları" v-if="settings" dropleft size="sm" :variant="c_anySettingsApplied ? 'flat-danger' : 'flat-dark'" toggle-class="btn-icon" class="mr-25 ml-25" no-caret>
                                <template #button-content>
                                    <feather-icon icon="SettingsIcon" size="16"></feather-icon>
                                </template>
                                <div class="pr-50 pl-50">
                                    <draggable :list="d_fields" :group="{name:'parent'}" handle=".handler" draggable=".item" v-on:end="updateStoreTable">
                                        <div class="p-25 item" v-for="field in d_fields" :key="field.id" >
                                            <div class="d-flex align-items-center">
                                                <span class="material-icons-round mr-25 handler">drag_indicator</span>
                                                <b-form-checkbox :disabled="d_fields.filter(c=>c.visible).length == 1 && field.visible == true" :id="field.id" class="text-nowrap" v-model="field.visible" v-on:change="controlRelatedVisible(field,true);updateStoreTable()"><span class="pr-50">{{ field.name }}</span></b-form-checkbox>
                                            </div>
                                            <div class="mt-50 alert alert-secondary mb-0 pt-25 pb-25 pl-50 pr-50" v-if="fieldHasChildren(field)">
                                                <draggable v-model="field.children" handle=".handler" :group="{name:'child'+field.id}" v-on:end="updateStoreTable">
                                                    <div v-for="(child) in field.children" :key="child.id" class="d-flex align-items-center mt-25 mb-25">
                                                        <span class="material-icons-round mr-25  handler">drag_indicator</span>
                                                        <b-form-checkbox :disabled="d_fields.filter(c=>c.visible).length == 1 && field.visible == true" :id="child.id" class="text-nowrap" v-model="child.visible" v-on:change="controlRelatedVisible(field,false);updateStoreTable()"><span class="pr-50"><b class="text-secondary"> {{ field.name }} </b> | {{ child.name }}</span></b-form-checkbox>
                                                    </div>
                                                </draggable>
                                            </div>
                                        </div>
                                    </draggable>
                                </div>
                            </b-dropdown>
                            <b-input-group v-if="search" class="input-group-merge v-table-search-input ml-25">
                                <template #prepend>
                                    <b-input-group-text >
                                        <feather-icon icon="SearchIcon"></feather-icon>
                                    </b-input-group-text>
                                </template>
                                <b-form-input v-on:keyup="searchOnTable" v-model="searchValue" placeholder="Ara..." ></b-form-input>
                            </b-input-group>
                        </div>
                    </div>
                </div>
            </div>
            <div class="v-table-filters">
                <b-collapse v-model="showFilters">
                    <div class="bg-light rounded mb-1 mx-1 p-75">
                        <slot name="filters"></slot>
                    </div>
                </b-collapse>
            </div>
            <div>
                <slot name="custom-html"></slot>
            </div>
            <div class="mb-1 mx-1 p-50 rounded border" v-if="(pagination == 'server' || pagination == 'client') && c_total > 0 && perPage > 10 && (c_items || []).length > 10">
                <div class="row align-items-center justify-content-center justify-content-md-between">
                    <div class="col-auto mb-1 mb-md-0">
                        <div class="d-flex align-items-center v-table-per-page">
                            <span>
                                Toplam {{c_total}} kayıt arasıdan
                            </span>
                            <select class="form-control form-control-sm" v-model="c_perPage" v-on:change="onPerPageChange">
                                <option v-for="item in perPages" :value="item" :key="item">{{item}}</option>
                            </select>
                            <span>
                                kayıt gösteriliyor.
                            </span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <b-pagination v-if="pagination == 'server' || pagination == 'client'" v-model="page" class="my-0" :total-rows="c_total" :per-page="perPage" v-on:input="pagination == 'server' ? refresh() : null"></b-pagination>
                    </div>
                </div>
            </div>
            <div v-if="selectable" class="v-table-selected-count-text font-weight-bold">Toplam <b>{{c_selectedRows.length}}</b> satır seçildi</div>
            <vue-perfect-scrollbar tag="div" class="overflow-hidden">
                <table ref="table" class="table table-hover mb-1 table-sm align-middle v-table">
                    <thead class="v-table-thead table-bordered">
                        <template v-if="c_hasAdditionalThead">
                            <slot name="additional-thead"></slot>
                        </template>
                        <tr>
                            <th v-if="selectable" :rowspan="c_fieldsHasAnyChild ? 2 : null" class="py-05 align-middle">
                                <b-form-checkbox v-on:change="controlSelect(true)" v-model="selectAll" class="pl-2 d-inline-block"></b-form-checkbox>
                            </th>
                            <th v-if="buttons.length" :rowspan="c_fieldsHasAnyChild ? 2 : null" class="py-05 align-middle">İşlemler</th>
                            <template v-for="(field,index) in d_fields">
                                <th v-if="field.visible" :colspan="colspan(field)" :rowspan="!colspan(field) ? 2 : null" :class="[isOrdered(field) ? 'current-order' : null, field.sortable ? 'sortable' : null,isSticky(field)? 'v-table-column-sticky':null ,field.thClass]" v-on:click="field.sortable ? setOrder(field) : null" class="py-05 align-middle" :key="index">
                                    <div class="text-nowrap d-flex align-items-center justify-content-between">
                                        <span v-if="$safe(field,'tooltip')" v-b-tooltip.noninteractive.hover.bottom :title="$safe(field,'tooltip')">{{field.name}}</span>
                                        <span v-else class="text-nowrap" >{{field.name}}</span>
                                        <div v-if="field.sortable" class="ml-1">
                                            <b-icon-x-circle-fill v-on:click.stop="clearSort" class="clear-sort-icon" v-if="isOrdered(field)"></b-icon-x-circle-fill>
                                            <b-icon-sort-up v-if="ascending && isOrdered(field)" class="sort-icon"></b-icon-sort-up>
                                            <b-icon-sort-down v-else  class="sort-icon"></b-icon-sort-down>
                                        </div>
                                    </div>
                                </th>
                            </template>
                        </tr>
                        <tr v-if="c_fieldsHasAnyChild">
                            <template v-for="(field,index) in c_fieldsChildren" >
                                <th v-if="field.visible" :key="index" class="align-middle" :class="[isOrdered(field) ? 'current-order' : null, field.sortable ? 'sortable' : null,isSticky(field)? 'v-table-column-sticky':null,field.thClass]" v-on:click="field.sortable ? setOrder(field) : null">
                                    <div class="text-nowrap d-flex align-items-center justify-content-between">
                                        <span v-if="$safe(field,'tooltip')" v-b-tooltip.noninteractive.hover.bottom :title="$safe(field,'tooltip')">{{field.name}}</span>
                                        <span v-else class="text-nowrap" >{{field.name}}</span>
                                        <div v-if="field.sortable" class="ml-1">
                                            <b-icon-x-circle-fill v-on:click.stop="clearSort" class="clear-sort-icon" v-if="isOrdered(field)"></b-icon-x-circle-fill>
                                            <b-icon-sort-up v-if="ascending && isOrdered(field)" class="sort-icon"></b-icon-sort-up>
                                            <b-icon-sort-down v-else  class="sort-icon"></b-icon-sort-down>
                                        </div>
                                    </div>
                                </th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>  
                        <template v-if="rowSlot == 'in'">
                            <tr v-for="(item,index) in c_items" :key="index" :class="currentLine == index ? 'v-table-selected-row': null">
                                <td v-if="selectable" class="text-center">
                                    <b-form-checkbox v-on:change="controlSelect(false)" v-model="item.selected" class="pl-2 d-inline-block"></b-form-checkbox>
                                </td>
                                <td v-if="buttons.length" :class="buttonTdClass">
                                    <b-dropdown v-if="buttonsInDropdown" :boundary="$refs.table" dropright size="sm" variant="light" no-caret :disabled="cellLoading">
                                        <template #button-content>
                                            <b-spinner small class="mr-50" v-if="cellLoading && currentLine == index"></b-spinner>
                                            <feather-icon v-else class="mr-50" icon="MoreHorizontalIcon"></feather-icon>
                                            {{buttonsInDropdownText ? buttonsInDropdownText : 'İşlemler'}}
                                        </template>
                                        <template v-for="(button,b_index) in buttons">
                                            <b-dropdown-item v-on:click="currentLine = index; currentButton = button.key; button.action(item,button.key,index);" size="sm" v-if="buttonCondition(button,item,index)" :variant="$safe(button,'variant') || 'primary'" :disabled="cellLoading" :key="b_index" >
                                                <feather-icon class="mr-50" :icon="button.icon" v-if="btnHasIcon(button)"></feather-icon>
                                                {{button.text}}
                                            </b-dropdown-item>
                                        </template>
                                    </b-dropdown>
                                    <div v-else class="text-nowrap d-flex g-25" :class="buttonAlignment">
                                        <template v-for="(button,b_index) in buttons">
                                            <button v-if="buttonCondition(button,item,index)" v-b-tooltip.noninteractive.hover.bottom :title="button.text" :key="b_index" class="btn btn-icon btn-sm waves-effect" :disabled="cellLoading" :class="[btnVariant(button)]" v-on:click="currentLine = index; currentButton = button.key; button.action(item,button.key,index);">
                                                <b-spinner small v-if="cellLoading && currentLine == index && currentButton == button.key"></b-spinner>
                                                <template v-else>
                                                    <feather-icon :icon="button.icon" v-if="btnHasIcon(button)"></feather-icon>
                                                    <template v-else>{{$t(button.text)}}</template>
                                                </template>
                                            </button>
                                        </template>
                                    </div>
                                </td>
                                <slot name="row" :row="item"></slot> 
                            </tr>
                        </template>
                        <template v-else-if="rowSlot == 'out'">
                            <template v-for="item in c_items">
                                <slot name="row-out" :row="item"></slot> 
                            </template>
                        </template>
                        <template v-else>
                            <tr v-for="(item,index) in c_items" :key="index" :class="[currentLine == index ? 'v-table-selected-row': null, rowClass ? rowClass(item,index) : null]">
                                <td v-if="selectable" class="text-center">
                                    <b-form-checkbox v-on:change="controlSelect(false)" v-model="item.selected" class="pl-2 d-inline-block"></b-form-checkbox>
                                </td>
                                <td v-if="buttons.length" :class="buttonTdClass">
                                    <b-dropdown v-if="buttonsInDropdown" :boundary="$refs.table" dropright size="sm" variant="light" no-caret :disabled="cellLoading">
                                        <template #button-content>
                                            <b-spinner small class="mr-50" v-if="cellLoading && currentLine == index"></b-spinner>
                                            <feather-icon v-else class="mr-50" icon="MoreHorizontalIcon"></feather-icon>
                                            {{buttonsInDropdownText ? buttonsInDropdownText : 'İşlemler'}}
                                        </template>
                                        <template v-for="(button,b_index) in buttons">
                                            <b-dropdown-item v-on:click="currentLine = index; currentButton = button.key; button.action(item,button.key,index);" size="sm" v-if="buttonCondition(button,item,index)" :variant="$safe(button,'variant') || 'primary'" :disabled="cellLoading" :key="b_index" >
                                                <feather-icon class="mr-50" :icon="button.icon" v-if="btnHasIcon(button)"></feather-icon>
                                                {{button.text}}
                                            </b-dropdown-item>
                                        </template>
                                    </b-dropdown>
                                    <div v-else class="text-nowrap d-flex g-25" :class="buttonAlignment">
                                        <template v-for="(button,b_index) in buttons">
                                            <button v-if="buttonCondition(button,item,index)" v-b-tooltip.noninteractive.hover.bottom :title="button.text" :class="[btnVariant(button)]" :key="b_index" :disabled="cellLoading" class="btn btn-icon btn-sm waves-effect" v-on:click="currentLine = index; currentButton = button.key; button.action(item,button.key,index);">
                                                <b-spinner small v-if="cellLoading && currentLine == index && currentButton == button.key"></b-spinner>
                                                <template v-else>
                                                    <feather-icon :icon="button.icon" v-if="btnHasIcon(button)"></feather-icon>
                                                    <template v-else>{{button.text}}</template>
                                                </template>
                                            </button>
                                        </template>
                                    </div>
                                </td>
                                <template v-for="(field,f_index) in c_flatFields">
                                    <td v-if="field.visible" :class="[field.tdClass,isSticky(field) ? 'v-table-column-sticky':null]" :key="f_index">
                                        <template v-if="fieldType(field) == 'component'">
                                            <component v-if="components[$safe(field,'componentOptions.name')]" :is="components[$safe(field,'componentOptions.name')]" v-bind="{'row':item,'field':getItem(item,field.key)}" v-on:action="onComponentAction"></component>
                                        </template>
                                        <template v-else-if="fieldType(field) == 'img'">
                                            <div class="text-center image-field">
                                                <img v-image-error="getImageOption(field).placeholder" v-if="getItem(item,field.key)"  class="v-table-img" :src="getItem(item,field.key)" :class="getImageOption(field).class">
                                                <img v-else :src="getImageOption(field).placeholder" class="v-table-img" :class="getImageOption(field).class"/>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div v-if="getItem(item,field.key) === true || getItem(item,field.key) === false" class="text-center">
                                                <span v-if="getItem(item,field.key) === true" class="material-icons-outlined d-block text-success">check_circle</span>
                                                <span v-else class="material-icons-outlined d-block text-danger">cancel</span>
                                            </div>
                                            <span v-else>{{getItem(item,field.key) || '-'}}</span>
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </template>
                        <tr v-if="c_items.length == 0"><td colspan="100" class="text-center">{{emptyText}}</td></tr>
                    </tbody>
                </table>
            </vue-perfect-scrollbar>
            <div class="mt-1 px-1" v-if="(pagination == 'server' || pagination == 'client') && c_total > 0">
                <div class="row align-items-center justify-content-center justify-content-md-between">
                    <div class="col-auto mb-1 mb-md-0">
                        <div class="d-flex align-items-center v-table-per-page">
                            <span>
                                Toplam {{c_total}} kayıt arasıdan
                            </span>
                            <select class="form-control form-control-sm" v-model="c_perPage" v-on:change="onPerPageChange">
                                <option v-for="item in perPages" :value="item" :key="item">{{item}}</option>
                            </select>
                            <span>
                                kayıt gösteriliyor.
                            </span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <b-pagination v-if="pagination == 'server' || pagination == 'client'" v-model="page" class="my-0" :total-rows="c_total" :per-page="perPage" v-on:input="pagination == 'server' ? refresh() : null"></b-pagination>
                    </div>
                </div>
            </div>
        </b-overlay>
        <b-popover v-if="c_hasInformation" variant="info" target="v-table-info" triggers="hover" placement="bottom">
            <template #title>Tablo Bilgileri</template>
            <slot name="information"></slot>
        </b-popover>
    </div>
</template>

<script>
import { VBTooltip,BOverlay,BInputGroup,BFormInput,BInputGroupText,BPagination,BFormSelect,BFormSelectOption,BFormCheckbox,BDropdown,BDropdownItem,BSpinner,BPopover,BCollapse } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BIconSortDown,BIconSortUp,BIconXCircleFill,BIconFileEarmarkSpreadsheetFill } from 'bootstrap-vue'
import randomatic from 'randomatic'
import modal from "@/utilities/modal"
import draggable from "vuedraggable"
import objectPath from 'object-path'
import { defineAsyncComponent } from 'vue-demi'
import table from '@/utilities/table'
import { filter } from 'postcss-rtl/lib/affected-props'

export default {
    components:{
        BOverlay,
        BSpinner,
        BInputGroup,
        BFormInput,
        BInputGroupText,
        BPagination,
        BFormSelect,
        BFormSelectOption,
        BFormCheckbox,
        BDropdown,
        BDropdownItem,
        BIconSortUp,
        BIconSortDown,
        BIconXCircleFill,
        BPopover,
        VuePerfectScrollbar,
        BIconFileEarmarkSpreadsheetFill,
        draggable,
        BCollapse
    },
    directives:{
        "b-tooltip":VBTooltip,
    },
    props:{
        withItems:{
            type:Boolean,
            default:false,
        },
        selectable:{
            type:Boolean,
            default:false,
        },
        settings:{
            type:Boolean,
            default:true
        },
        items:{
            type:Array,
            default:()=>[]
        },
        itemsProvider:{
            type:Function,
            default:null
        },
        addCallback:{
            type:Function,
            default:null
        },
        rowSlot:{
            type:String,
            default:"default",
            validator(value) {
                return ['default', 'in','out'].includes(value)
            }
        },
        emptyText:{
            type:String,
            default:"Herhangi bir veri bulunamadı."
        },
        fields:{
            type:Array,
            default:()=>[]
        },
        pagination:{
            type:String,
            default:null,
            validator(value) {
                return ['client', 'server',null].includes(value)
            }
        },
        search:{
            type:Boolean,
            default:false,
        },
        buttons:{
            type:Array,
            default:()=>[]
        },
        buttonAlignment:{
            type:String,
            default:"justify-content-center"
        },
        buttonTdClass:{
            type:String,
            default:""
        },
        excelCallback:{
            type:Function,
            default:null
        },
        overlayClass:{
            type:String,
            default:"pb-1"
        },
        rowClass:{
            type:Function,
            default:null
        },
        buttonsInDropdown:{
            type:Boolean,
            default:false
        },
        buttonsInDropdownText:{
            type:String,
            default:null
        },
        name:{
            type:String,
            default:null,
            required:false
        },
        initialLoad:{
            type:Boolean,
            default:true
        },
        initialPerPage:{
            type:Number,
            default:10
        }
    },
    data(){
        return{
            d_items: this.withItems ? structuredClone(this.items) : [],
            d_fields: structuredClone(this.fields),
            total:0,
            loading:false,
            cellLoading:false,
            excelLoading:false,
            perPages:[10,20,30,40,50,100],
            currentLine:null,
            currentButton:null,
            page:1,
            perPage:10,
            searchValue:"",
            ascending:null,
            order:null,
            filteredLength:0,
            searchOnTableTimer:null,
            selectAll:false,
            components:{},
            showFilters:false
        }
    },
    watch:{
        c_isModalOpen(nevValue,oldValue){
            if(nevValue === false){
                setTimeout(()=>{
                    this.currentLine = null
                },500)
            }
        },
        items(){
            this.d_items = this.items
        },
        order(){
            this.page = 1
        },
        fields(){
            this.d_fields = structuredClone(this.fields)
            this.configure()
        },
    },
    created(){
        this.configure()
        this.configureFromStoreTable()
    },
    mounted(){
        if(!this.withItems && this.initialLoad) this.refresh()
    },  
    computed:{
        c_selectedRows(){
            return this.c_items.filter(item=>item.selected)
        },
        c_additionalActions(){
            return !!this.$slots["additional-actions"]
        },
        c_fieldsHasAnyChild(){
            const children = this.d_fields.map(f=> f?.children?.length || 0)
            if(children.length) return children.reduce((a,b)=>a+b) > 0 ? true : false
            else return false
        },
        c_fieldsChildren(){
            return this.d_fields.flatMap(f=> f?.children || [])
        },
        c_flatFields(){
            return this.d_fields.reduce((newArray,field)=>{
                if(field.children) newArray.push(...field.children)
                else newArray.push(field)
                return newArray
            },[])
        },
        c_anySettingsApplied(){
            let fields = this.c_flatFields
            let visible = fields.filter(f=> f.visible)
            if(visible.length == fields.length) return false
            return true
        },
        c_items(){
            if(this.pagination == 'client'){
                if(this.searchValue.trim().length != 0){
                    let items = this.d_items.filter(item=>{
                        if(typeof item == 'object'){
                            return this.fields.map(f=>f.key).some(key=>{
                                return objectPath.get(item,key)?.toString().toLowerCase().includes(this.searchValue.toLowerCase())
                            })
                        }else{
                            return item.toString().toLowerCase().includes(this.searchValue.toLowerCase())
                        }
                    })
                    this.filteredLength = items.length
                    return items.slice((this.page-1)*this.perPage,this.page*this.perPage)
                }else return this.d_items.slice((this.page-1)*this.perPage,this.page*this.perPage)
            }else if(this.pagination == "server"){
                return this.d_items
            }else {
                if(this.searchValue.trim().length != 0){
                    let items = this.d_items.filter(item=>{
                        if(typeof item == 'object'){
                            return this.fields.map(f=>f.key).some(key=>{
                                return objectPath.get(item,key)?.toString().toLowerCase().includes(this.searchValue.toLowerCase())
                            })
                        }else{
                            return item?.toString().toLowerCase().includes(this.searchValue.toLowerCase())
                        }
                    })
                    this.filteredLength = items.length
                    return items
                }else return this.d_items
            }
        },
        c_perPage: {
            set: function(val) { this.perPage = Number(val) },
            get: function() { return Number(this.perPage) }
        },
        c_total(){
            if(this.withItems && this.pagination != 'client') return this.c_items.length
            else if(this.pagination == 'client' && this.searchValue.trim().length != 0) return this.filteredLength
            else if(this.pagination == 'client') return this.d_items.length
            else return this.total
        },
        c_totalPage(){
            return Math.ceil(this.c_total/this.perPage)
        },
        c_isModalOpen(){
            return modal.isModalOpen
        },
        c_hasInformation(){
            return !!this.$slots["information"]
        },
        c_hasFilters(){
            return !!this.$slots["filters"]
        },
        c_hasAdditionalThead(){
            return !!this.$slots["additional-thead"]
        }
    },
    methods: {
        onComponentAction(){
            this.$emit('component-action',...arguments)
        },
        onPerPageChange(){
            table.updateTableField(this.name,"perPage",this.perPage)
            this.pagination == 'server' ?  this.refresh() : null
        },
        configureFromStoreTable(){
            if(this.name){
                const perPage = table.getTableField(this.name,'perPage') || this.initialPerPage
                this.perPage = perPage
            }
            // if(this.name){
            //     let storeTable = table.getTable(this.name)
            //     if(!storeTable){
            //         table.addTable({
            //             name:this.name,
            //             perPage:this.perPage,
            //             keys:this.d_fields?.map(f=>{
            //                 return {
            //                     key: f.key ? f.key : f.children?.map(c=>{
            //                         return{
            //                             key:c.key,
            //                             visible:c.visible
            //                         }
            //                     }),
            //                     visible:f.visible
            //                 }
            //             })
            //         })
            //     }else{
            //         this.d_fields = storeTable.keys.reduce((arr,st)=>{
            //             if(Array.isArray(st.key)){
            //                 let df = this.d_fields.find(d=>{
            //                     return d.children?.map(c=>c.key).sort().toString() == st.key.map(c=>c.key).sort().toString()
            //                 })
            //                 if(df){
            //                     arr.push({
            //                         ...df,
            //                         visible:st.visible,
            //                         children:st.key.reduce((_arr,c)=>{
            //                             let ch = df.children.find(cc=>cc.key == c.key)
            //                             _arr.push({
            //                                 ...ch,
            //                                 visible:c.visible
            //                             })
            //                             return _arr
            //                         },[])
            //                     })
            //                     return arr
            //                 }else return arr
            //             }else{
            //                 let df = this.d_fields.find(d=>d.key == st.key)
            //                 arr.push({
            //                     ...df,
            //                     visible:st.visible
            //                 })
            //                 return arr
            //             }
            //         },[])
            //     }
            //     this.configureTableColumnWidth()
            // }
        },
        updateStoreTable(){
            // table.updateTable({
            //     name:this.name,
            //     keys:this.d_fields.map(f=>{
            //         return {
            //             key: f.key ? f.key : f.children.map(c=>{
            //                 return{
            //                     key:c.key,
            //                     visible:c.visible
            //                 }
            //             }),
            //             visible:f.visible
            //         }
            //     })
            // })
            // this.configureTableColumnWidth()
        },
        configureTableColumnWidth(){
            const getWidth = (str) => {
                let reg = str?.match(/\w-(.*)/)[1]
                if(reg){
                    let secReg = reg?.replace(/\s(.*)/,'')
                    if(secReg){
                        return Number(secReg)
                    }else return 0
                } else return 0 
            }

            const cleanWidth = (str) => {
                return str?.replace(/w-(\d\d\d|\d\d|)/,'')
            }

            let currentWitdh = this.d_fields.filter(rf=>rf.visible).map(rf=>{
                if(rf.children) {
                    return rf.children?.filter(c=>c.visible).map(c=>getWidth(c.thClass)).reduce((a,b)=>a += b)
                }else return getWidth(rf.thClass)   
            }
            ).reduce((a,b)=>a += b)
            let requiredWidth = 100 - currentWitdh
            let perWidth = Math.ceil(requiredWidth / this.d_fields.filter(rf=>rf.visible).length)
            this.d_fields = this.d_fields.reduce((arr,rf)=>{
                if(this.requireColumnWidthUpdate()){
                    if(rf.children){
                        let children = rf.children?.reduce((carr,c)=>{
                            let cWidth = getWidth(c.thClass)
                            let thClass = `${cleanWidth(c.thClass)} w-${ (cWidth + perWidth) }` 
                            carr.push({
                                ...c,
                                thClass
                            })
                            return carr
                        },[]) 
                        arr.push({
                            ...rf,
                            children
                        })
                        return arr
                    }else{
                        if(rf.thClass && rf.visible){
                            let cWidth = getWidth(rf.thClass)
                            let thClass = `${cleanWidth(rf.thClass)} w-${ (cWidth + perWidth) }` 
                            arr.push({
                                ...rf,
                                thClass
                            })
                        }else arr.push(rf)
                    }
                    return arr
                }else{
                    let f = this.fields.find(f => f.key == rf.key)
                    if(f?.thClass){
                        if(f.children){
                            let children = f.children.reduce((carr,c)=>{
                                let cWidth = getWidth(c.thClass)
                                let thClass = `${cleanWidth(c.thClass)} w-${ (cWidth) }` 
                                carr.push({
                                    ...c,
                                    thClass
                                })
                                return carr
                            },[])
                            arr.push({
                                ...f,
                                children
                            })
                        }
                        let cWidth = getWidth(f.thClass)
                        let thClass = `${cleanWidth(f.thClass)} w-${ (cWidth) }` 
                        arr.push({
                            ...rf,
                            thClass
                        })
                    }else arr.push(rf)
                    return arr
                }
            },[])
        },
        requireColumnWidthUpdate(){
            return this.d_fields.filter(c=> c?.children?.every(c=>c.visible == true)).length == 0 || this.d_fields.filter(f=>f.visible).length != this.d_fields.length
        },
        buttonCondition(button,item,index){
            if(button?.condition){
                return button.condition(item,button.key,index)
            }else return true
        },
        configure(){
            this.importAsyncComponents()
            this.d_fields = this.d_fields.map(field=>{
                return {
                    ...field,
                    visible: true,
                    id: randomatic('A0',10),
                    children: field?.children?.map(child=>{
                        return {
                            ...child,
                            visible: true,
                            id: randomatic('A0',10)
                        }
                    }) || null
                }
            })
        },
        importAsyncComponents(){
            this.c_flatFields.filter(f=> f.component).forEach(c=>{
                if(c.componentOptions?.name){
                    this.components[c.componentOptions.name] = defineAsyncComponent(()=> import(`@/views/common/${c.componentOptions.name}.vue`))
                }
            })
        },
        sortFields(a,b){
            a.order - b.order
        },
        controlRelatedVisible(field,bool){
            bool ? field?.children?.forEach(c=>{ c.visible = field.visible }) : null
            if(field.children){
                let children = field.children
                let visible = children.filter(c=>c.visible)
                if( visible.length == 0 ) field.visible = false
                else field.visible = true
            }
            this.$forceUpdate()
        },
        clearSort(){
            this.ascending = false
            this.order = null
            this.refresh()
        },
        setOrder(field){
            let sortKey = null
            if(field?.sortKey){ sortKey = field.sortKey }
            else if(field?.key){ sortKey = field.key }
    
            if(this.order == sortKey) this.ascending = !this.ascending
            else {
                this.order = sortKey
                this.ascending = true
            }
            this.refresh()
        },
        isOrdered(field){
            if((field?.sortKey != null || field?.key != null) && this.order != null){
                return this.order == field?.sortKey || this.order == field?.key ? true : false
            }
            else return false
        },
        isSticky(field){
            if(field?.sticky) return field.sticky
            else return false
        },
        colspan(field){
            return field?.children?.filter(c=>c.visible).length || null
        },
        fieldType(field){
            if(field?.component) return "component"
            if(field?.img) return "img"
            return "text"
        },
        fieldHasChildren(field){
            return field?.children?.length ? true : false
        },
        btnHasIcon(button){
            return button?.icon ? true : false
        },
        btnVariant(button){
            return button?.variant ? 'btn-flat-' + button.variant : 'btn-primary'
        },
        setPage(page){
            this.page = page
            if(!this.pagination == 'client' && this.pagination == 'server') this.refresh()
            else this.$emit("page-change",this.P)
        },
        getItem(item,key){
            let _key = key
            if(key?.startsWith('[') && key?.endsWith(']')) _key = JSON.parse(key)
            let data = objectPath.get(item,_key)
            switch (data) {
                case 0: return data.toString()
                default: return data
            }
        },
        getComponent(field){
            if(field?.componentOptions?.name){
                return defineAsyncComponent(()=> import(`@/views/common/${field.componentOptions.name}.vue`))
            }else return null
        },
        getComponetOptions(field){
            if(field?.componentOptions?.data){
                return field.componentOptions.data
            }else return {}
        },
        setItems(items){ this.d_items = items },
        setLoading(loading){ this.loading = loading },
        setExcelLoading(loading){ this.excelLoading = loading},
        setTotal(total){ this.total = total},
        setPerPage(perPage){ this.perPage = perPage },
        datas(){
            return {
                Skip: this.page,
                Take: this.perPage,
                QueryParam: this.searchValue,
                IsAscending:this.ascending ? true : false,
                OrderColumnName:this.order,
            }
        },
        refresh(){
            if(this.itemsProvider){
                this.loading = true
                this.itemsProvider(this.datas()).then(response=>{
                    if(this.pagination == 'server'){
                        this.d_items = response.data.Data.Data
                        this.total = response.data.Data.TotalCount
                    }else{
                        this.d_items = response.data.Data
                        this.total = response.data.Data.length
                    }
                    this.loading = false
                    this.selectAll = false
                    this.$emit('select',this.c_selectedRows)
                    if(this.pagination == 'server') this.pageControl()
                    this.$emit('refresh')
                }).catch(error=>{ this.loading = false })
            }
        },
        rowLoading(bool){
            this.cellLoading = bool
            this.$root.$emit('bv::hide::tooltip')
        },
        pageControl(){
            if(this.d_items.length == 0 && this.page > 1) this.setPage(this.page - 1)
        },
        searchOnTable(){
            if(this.pagination == "server"){
                if(this.searchValue.trim().length >= 0){
                    function search(){
                        clearTimeout(this.searchOnTableTimer)
                        this.searchOnTableTimer = setTimeout(()=>{ 
                            this.$emit('search',this.searchValue)
                            this.refresh() 
                        },350)
                    }
                    clearTimeout(this.searchOnTableTimer)
                    this.searchOnTableTimer = setTimeout(()=>{ search.call(this) },350)
                }
            }else this.$emit('search',this.searchValue)
        },
        controlSelect(bool){
            if(bool) this.c_items.forEach(item=>this.$set(item,"selected",this.selectAll))
            if(this.c_items.length == this.c_items.filter(item=>item.selected).length) this.selectAll = true
            else this.selectAll = false
            this.$emit('select',this.c_selectedRows)
        },
        getImageOption(field){
            return{
                class:field?.imgOptions?.class || "",
                placeholder:field?.imgOptions?.placeholder || require("@/assets/images/illustrations/no-image.svg"),
            }
        },
        dataVisibility(type,key,visible){
            if(type == "name") this.c_flatFields.filter(f=> f.name == key).forEach(f=> this.$set(f,'visible',visible))
            else if(type == "key") this.c_flatFields.filter(f=> f.key == key).forEach(f=> this.$set(f,'visible',visible))
        }
    }
}
</script>

<style>
    .v-table-selected-row:not(:hover){
        background-color:rgba(31, 70, 144,0.15);
    }
    .v-table-selected-count-text{
        font-size: 0.8rem;
        color: #868e96;
        padding: 0 1rem 0.5rem 1rem;
    }
    .v-table-per-page span{
        font-size: 0.85rem;
    }
    .v-table-per-page select{
        width: 60px;
        margin: 0 0.5rem;
    }
    .v-table-go-page .custom-select{
        border-right: 0px;
        box-shadow: none !important;
    }
    .py-05{
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .table thead.v-table-thead tr:nth-child(2) th:not(.current-order){
        background-color: hsl(252, 24%, 93%) !important;
    }

    .table thead.v-table-thead th {
        border: 1px solid #d4d2de !important;
    }

    .table thead.v-table-thead th:first-child{
        border-left-color: #f3f2f7 !important;
    }

    .table thead.v-table-thead th:last-child{
        border-right-color: #f3f2f7 !important;
    }


    .dark-layout .table thead.v-table-thead tr:nth-child(2) th:not(.current-order){
        background-color: hsl(224, 24%, 24%) !important;
    }

    .dark-layout .table thead.v-table-thead th {
        border-color: rgb(75, 82, 103) !important;
    }

    .dark-layout .table thead.v-table-thead th:first-child{
        border-left-color: #343d55 !important;
    }

    .dark-layout .table thead.v-table-thead th:last-child{
        border-right-color: #343d55 !important;
    }

    .v-table th.sortable{
        cursor: pointer;
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -o-user-select: text;
        user-select: text;
    }

    .v-table .sort-icon{
        color:hsl(224, 24%, 28%) !important;;
        font-size:1rem;
        margin-left:0.35rem;
    }

    .v-table .clear-sort-icon{
        color:#ea5455;
    }

    .dark-layout .v-table .sort-icon{
        color: #d0d2d6 !important;
    }

    .v-table th.current-order{
        color: black;
        background-color: hsl(252, 24%, 88%) !important;
    }

    .dark-layout .v-table th.current-order{
        color: white;
        background-color: hsl(224, 24%, 35%) !important;
    }

    .v-table th.current-order .sort-icon{
        color:black !important;
    }

    .dark-layout .v-table th.current-order .sort-icon{
        color:white !important
    }

    [dir=ltr] .v-table.table tbody tr:last-child td:first-child{
        border-bottom-left-radius: 0px !important
    }

    [dir=ltr] .v-table.table tbody tr:last-child td:last-child{
        border-bottom-right-radius: 0px !important
    }

    .v-table-container .handler{
        cursor: pointer;
    } 

    .v-table-container .handler:hover{
        color: #7367f0;
    }

    .v-table-img{
        height: 30px;
        width: auto;
    }

    .v-table-column-sticky{
        position: sticky;
        left: -1px;
        /* box-shadow: inset -1px 0px 0px 0px #d4d2de; */
    }

    .v-table-column-sticky::after{
        height: 100%;
        width: 1px;
        position: absolute;
        content: "";
        background-color: #d4d2de;
        top: 0;
        right: -1px;
    }

    .v-table-column-sticky:not(th){
        background-color: white;
    }

    .dark-layout .v-table-column-sticky:not(th){
        background-color: #283046 !important;
    }

    .dark-layout .v-table-column-sticky{
        box-shadow: inset -1px 0px 0px 0px rgb(75, 82, 103);
    }

    .v-table-info{
        display: flex;
        font-size: 1rem;
        padding: 0.5rem;
    }

    .v-table-info svg{
        height: 16px;
        width: 16px;
    }

    .image-field{
        background-color: hsl(250, 33%, 98%);
        border-radius: 0.25rem;
    }

    .dark-layout .image-field{
        background-color: hsl(224, 24%, 30%);
    }
    
</style>